const sidenav = document.querySelector('#sidenav-open')
const closenav = document.querySelector('#sidenav-close')
const opennav = document.querySelector('#sidenav-button')

// set focus to our open/close buttons after animation
sidenav.addEventListener('transitionend', e => {
  if (e.propertyName !== 'transform')
    return
  const isOpen = document.location.hash === '#sidenav-open'
  isOpen
    ? closenav.focus()
    : opennav.focus()

  if (!isOpen) {
    history.replaceState(history.state, '')
  }
})

// close our menu when esc is pressed
sidenav.addEventListener('keyup', e => {
  if (e.code === 'Escape')
    window.history.length
      ? window.history.back()
      : document.location.hash = ''
})

const images = document.querySelectorAll('.lazy');
const config = {
	root: null,
	rootMargin: '0px',
};
let observer = new IntersectionObserver(function (entries, self) {
		entries.forEach(entry => {
	        if (entry.isIntersecting) {
        		const image = entry.target;
        
        		image.setAttribute('src', image.getAttribute('data-src'));
        		image.classList.add('loaded');
        
        		self.unobserve(image);
        	}
        });
	}, config
);
images.forEach(image => {
    observer.observe(image);
});
var target = document.querySelector("footer");
function callback(entries, observer) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      let actionCall = document.getElementById("action-bar");
      if (actionCall != null) {
        actionCall.style.display = "none";
      }
    } else {
      let actionCall = document.getElementById("action-bar");
      if(window.innerWidth <= 768 && actionCall != null){
        actionCall.style.display = "flex";
      }
    
    }
  });
}
let observer2 = new IntersectionObserver(callback);
observer2.observe(target);

const timeout = document.getElementsByClassName('alert')
if(timeout.length > 0) {
   setTimeout(hideElement, 5000) 
    function hideElement() {
      timeout[0].style.display = 'none';
    }
}